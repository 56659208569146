<div class="container my-5">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/employees">Funcionários</a></li>
      <li *ngIf="formGroup.get('id').value" class="breadcrumb-item active" aria-current="page">Alterar</li>
      <li *ngIf="formGroup.get('id').value == ''" class="breadcrumb-item active" aria-current="page">Adicionar Novo</li>
    </ol>
  </nav>

  <h1>Cadastro de Funcionário</h1>
  <!-- <div class="gap-2 mt-4">
    <button class="btn btn-warning print-button" (click)="generatePDF()">
      <i class="material-icons">print</i> Imprimir
    </button>
  </div> -->

  <div *ngIf="isSaving" class="backdrop">
    <div class="spinner-border text-light" role="status">
      <span class="visually-hidden">Salvando as Informações...</span>
    </div>
  </div>

  <div id="fullPageContent" class="container my-5">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <section class="mb-4">
        <h5 class="border-bottom pb-2">Informações Pessoais</h5>

        <div class="row">
          <div class="col-md-4 mb-3">
            <label for="name" class="form-label">Nome Completo</label>
            <input type="text" id="name" formControlName="name" class="form-control" placeholder="Seu nome"
                   [ngClass]="{'is-invalid': formGroup.get('name')?.invalid && formGroup.get('name')?.touched,
                   'is-valid': formGroup.get('name')?.valid && formGroup.get('name')?.touched}" />
            <div *ngIf="formGroup.get('name')?.invalid && formGroup.get('name')?.touched" class="invalid-feedback">
              O nome é obrigatório.
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <label for="email" class="form-label">Email</label>
            <input type="email" id="email" formControlName="email" class="form-control" placeholder="Seu email"
                   [ngClass]="{'is-invalid': formGroup.get('email')?.invalid && formGroup.get('email')?.touched,
                   'is-valid': formGroup.get('email')?.valid && formGroup.get('email')?.touched}" />
            <div *ngIf="formGroup.get('email')?.invalid && formGroup.get('email')?.touched" class="invalid-feedback">
              O email é obrigatório.
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <label for="cpfCnpj" class="form-label">CPF</label>
            <input type="text" id="cpfCnpj" formControlName="cpfCnpj" class="form-control" placeholder="000.000.000-00" mask="000.000.000-00"
                   [ngClass]="{'is-invalid': formGroup.get('cpfCnpj')?.invalid && formGroup.get('cpfCnpj')?.touched,
                   'is-valid': formGroup.get('cpfCnpj')?.valid && formGroup.get('cpfCnpj')?.touched}" />
            <div *ngIf="formGroup.get('cpfCnpj')?.invalid && formGroup.get('cpfCnpj')?.touched" class="invalid-feedback">
              O CPF é obrigatório.
            </div>
          </div>

          <div class="col-md-4 mb-3">
            <label for="birthdate" class="form-label">Data de Nascimento</label>
            <input type="date" id="birthdate" formControlName="birthdate" class="form-control"
                   [ngClass]="{'is-invalid': formGroup.get('birthdate')?.invalid && formGroup.get('birthdate')?.touched,
                   'is-valid': formGroup.get('birthdate')?.valid && formGroup.get('birthdate')?.touched}" />
            <div *ngIf="formGroup.get('birthdate')?.invalid && formGroup.get('birthdate')?.touched" class="invalid-feedback">
              A data de nascimento é obrigatório.
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <label for="gender" class="form-label">Gênero</label>
            <select id="gender" formControlName="gender" class="form-select"
                    [ngClass]="{'is-invalid': formGroup.get('gender')?.invalid && formGroup.get('gender')?.touched,
                   'is-valid': formGroup.get('gender')?.valid && formGroup.get('gender')?.touched}">
              <option value="" disabled selected>Selecione</option>
              <option value="Masculino">Masculino</option>
              <option value="Feminino">Feminino</option>
            </select>
            <div *ngIf="formGroup.get('gender')?.invalid && formGroup.get('gender')?.touched" class="invalid-feedback">
              O gênero é obrigatório.
            </div>
          </div>

          <div class="col-md-6 mb-3">
            <label for="username" class="form-label">Nome de usuário</label>
            <input type="text" id="username" formControlName="username" (input)="onInputChange()" class="form-control" placeholder="Seu usuário de acesso"
                   [ngClass]="{'is-invalid': formGroup.get('username')?.invalid && formGroup.get('username')?.touched,
                   'is-valid': formGroup.get('username')?.valid && formGroup.get('username')?.touched}" />
            <div *ngIf="formGroup.get('username')?.invalid && formGroup.get('username')?.touched" class="invalid-feedback">
              O nome de usuário é obrigatório.
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <label for="password" class="form-label">Senha</label>
            <input type="password" id="password" formControlName="password" class="form-control"
                   [ngClass]="{'is-invalid': formGroup.get('password')?.invalid && formGroup.get('password')?.touched,
                   'is-valid': formGroup.get('password')?.valid && formGroup.get('password')?.touched}" />
            <div *ngIf="formGroup.get('password')?.invalid && formGroup.get('password')?.touched" class="invalid-feedback">
              A senha é obrigatória.
            </div>
            <div *ngIf="formGroup.get('password')?.errors?.minlength && formGroup.get('password')?.touched" class="invalid-feedback">
              A senha deve ter no mínimo 6 dígitos.
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <label for="password" class="form-label">Confirme a Senha</label>
            <input type="password" id="confirmPassword" formControlName="confirmPassword" class="form-control"
                   [ngClass]="{'is-invalid': formGroup.get('confirmPassword')?.invalid && formGroup.get('confirmPassword')?.touched,
                   'is-valid': formGroup.get('confirmPassword')?.valid && formGroup.get('confirmPassword')?.touched}" />
            <div *ngIf="formGroup.get('confirmPassword')?.invalid && formGroup.get('confirmPassword')?.touched" class="invalid-feedback">
              Confirmar a senha é obrigatório.
            </div>
            <div *ngIf="formGroup.get('confirmPassword')?.errors?.minlength && formGroup.get('confirmPassword')?.touched" class="invalid-feedback">
              Confirmar senha deve ter no mínimo 6 dígitos.
            </div>
          </div>

          <div class="col-md-4 mb-3">
            <label for="officeId" class="form-label">Empresa</label>
            <select id="officeId" class="form-select" formControlName="officeId" (change)="onOfficeChange($event)"
                    [ngClass]="{'is-invalid': formGroup.get('officeId')?.invalid && formGroup.get('officeId')?.touched,
                   'is-valid': formGroup.get('officeId')?.valid && formGroup.get('officeId')?.touched}">
              <option value="" disabled selected>Selecione a Empresa</option>
              <option *ngFor="let office of offices" [value]="office['id']">{{office['name']}}</option>
            </select>
            <div *ngIf="formGroup.get('officeId')?.invalid && formGroup.get('officeId')?.touched" class="invalid-feedback">
              A empresa é obrigatória.
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <label for="responsibilityId" class="form-label">Cargo</label>
            <select id="responsibilityId" class="form-select" formControlName="responsibilityId" (change)="onResponsibilityChange($event)"
                    [ngClass]="{'is-invalid': formGroup.get('responsibilityId')?.invalid && formGroup.get('responsibilityId')?.touched,
                   'is-valid': formGroup.get('responsibilityId')?.valid && formGroup.get('responsibilityId')?.touched}">
              <option value="" disabled selected>Selecione o Cargo</option>
              <option *ngFor="let responsibility of responsibilities" [value]="responsibility['id']">{{responsibility['name']}}</option>
            </select>
            <div *ngIf="formGroup.get('responsibilityId')?.invalid && formGroup.get('responsibilityId')?.touched" class="invalid-feedback">
              O cargo é obrigatório.
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <label for="supervisorId" class="form-label">Supervisor</label>
            <select id="supervisorId" class="form-select" formControlName="supervisorId" (change)="onSupervisorChange($event)"
                    [ngClass]="{'is-invalid': formGroup.get('supervisorId')?.invalid && formGroup.get('supervisorId')?.touched,
                   'is-valid': formGroup.get('supervisorId')?.valid && formGroup.get('supervisorId')?.touched}">
              <option value="" disabled selected>Selecione o Supervisor</option>
              <option *ngFor="let supervisor of supervisors" [value]="supervisor['id']">{{supervisor['name']}}</option>
            </select>
            <div *ngIf="formGroup.get('supervisorId')?.invalid && formGroup.get('supervisorId')?.touched" class="invalid-feedback">
              O supervisor é obrigatório.
            </div>
          </div>

          <div class="col-md-8 mb-3">
            <label for="picture" class="form-label">Selecione a Foto</label>
            <input type="file" id="picture" formControlName="picture" class="form-control" (change)="onFileSelected($event)"
                   [ngClass]="{'is-invalid': formGroup.get('picture')?.invalid && formGroup.get('picture')?.touched,
                   'is-valid': formGroup.get('picture')?.valid && formGroup.get('picture')?.touched}">
            <div *ngIf="formGroup.get('picture')?.invalid && formGroup.get('picture')?.touched" class="invalid-feedback">
              A foto é obrigatória.
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <img [src]="imageUrl" alt="Foto do Colaborador" class="img-thumbnail" width="150">
          </div>
        </div>
      </section>

      <section class="mt-4 mb-4">
        <h5 class="border-bottom pb-2">Telefones</h5>

        <div class="row">
          <div class="col-md-4 mb-3">
            <label for="cellphone" class="form-label">Celular</label>
            <input type="text" id="cellphone" formControlName="cellphone" class="form-control" placeholder="(00) 00000-0000" mask="(00) 00000-0000">
          </div>
          <div class="col-md-4 mb-3">
            <label for="phone" class="form-label">Fixo</label>
            <input type="text" id="phone" formControlName="phone" class="form-control" placeholder="(00) 0000-0000" mask="(00) 0000-0000">
          </div>
        </div>
      </section>

      <section class="mt-4 mb-4">
        <h5 class="border-bottom pb-2">Endereço</h5>

        <div class="row">
          <div class="col-md-4 mb-3">
            <label for="cep" class="form-label">CEP</label>
            <input type="text" id="cep" formControlName="cep" class="form-control" placeholder="00.000-000" mask="00.000-000">
          </div>
          <div class="col-md-4 mb-3">
            <label for="uf" class="form-label">Estado</label>
            <input type="text" id="uf" formControlName="uf" class="form-control">
          </div>
          <div class="col-md-4 mb-3">
            <label for="city" class="form-label">Cidade</label>
            <input type="text" id="city" formControlName="city" class="form-control">
          </div>

          <div class="col-md-4 mb-3">
            <label for="streetName" class="form-label">Rua</label>
            <input type="text" id="streetName" formControlName="streetName" class="form-control">
          </div>
          <div class="col-md-4 mb-3">
            <label for="neighborhood" class="form-label">Bairro</label>
            <input type="text" id="neighborhood" formControlName="neighborhood" class="form-control">
          </div>
          <div class="col-md-4 mb-3">
            <label for="complement" class="form-label">Complemento</label>
            <input type="text" id="complement" formControlName="complement" class="form-control">
          </div>
        </div>
      </section>

      <section class="mt-4 mb-4">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="isSupervisor" formControlName="isSupervisor" />
          <label class="form-check-label" for="isSupervisor">Usuário é um Supervisor?</label>
        </div>
      </section>

      <div class="d-grid gap-2" *ngIf="!isSaving">
        <button type="submit" class="btn btn-primary" [disabled]="formGroup.invalid">Salvar</button>
      </div>

      <div *ngIf="isSaving" class="spinner-container">
        <div class="spinner"></div>
      </div>
    </form>

    <div *ngIf="successMessage" class="alert alert-success alert-dismissible fade show mt-4 mb-4" role="alert">
      {{ successMessage }}
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>

    <div *ngIf="errorMessage" class="alert alert-danger alert-dismissible fade show mt-4 mb-4" role="alert">
      {{ errorMessage }}
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
  </div>

</div>
