<mat-card class="card mat-elevation-z8">
  <mat-card-header>
    <mat-card-subtitle (click)="backClicked()" style="cursor: pointer"
      >Voltar</mat-card-subtitle
    >
    <mat-card-title
      >{{ goalId ? "Editar " + goal.title : "Cadastrar Meta" }}
    </mat-card-title>
    <mat-card-subtitle>Formulário para cadastro</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <form class="form">
      <!-- Divider -->
      <hr class="solid" />

      <!-- General -->
      <mat-card-subtitle>Dados Mestre:</mat-card-subtitle>
      <!-- Colunas -->
      <mat-grid-list cols="2" rowHeight="100px">
        <!-- Coluna 1 -->
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="legacy">
            <mat-label>Título</mat-label>
            <input
              [(ngModel)]="goal.title"
              name="name"
              [formControl]="nameFormControl"
              required
              matInput
            />
            <mat-icon matSuffix>add_business</mat-icon>
            <mat-hint
              *ngIf="!nameFormControl.value"
              align="end"
              style="color: red"
              >Campo obrigatório</mat-hint
            >
          </mat-form-field>
        </mat-grid-tile>

        <!-- Coluna 2 -->
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="outline">
            <mat-label>Selecione um Colaborador</mat-label>
            <mat-select
              [(ngModel)]="selectedPersonId"
              name="person"
              [formControl]="personsFormControl"
              required
              multiple
            >
              <mat-option
                *ngFor="let person of persons"
                value="{{ person.id }}"
                >{{ person.name }}</mat-option
              >
            </mat-select>
            <mat-hint
              *ngIf="!personsFormControl.value"
              align="end"
              style="color: red"
              >Campo obrigatório</mat-hint
            >
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
    </form>
  </mat-card-content>

  <mat-card-actions align="end">
    <button
      *ngIf="!this.goalId"
      (click)="save()"
      [disabled]="!validateFields()"
      class="success-button mat-elevation-z4"
      mat-raised-button
      extended
      matTooltip="Salvar as informações preenchidas"
    >
      <mat-icon>check</mat-icon> SALVAR
    </button>
    <button
      *ngIf="this.goalId"
      (click)="update()"
      [disabled]="!validateFields()"
      class="success-button mat-elevation-z4"
      mat-raised-button
      extended
      matTooltip="Alterar as informações preenchidas"
    >
      <mat-icon>check</mat-icon> ALTERAR
    </button>
    <button
      *ngIf="goalId"
      routerLink="../../"
      class="mat-elevation-z4"
      mat-raised-button
      color="warn"
      extended
      matTooltip="Cancelar as informações preenchidas e voltar a página"
    >
      <mat-icon>block</mat-icon> CANCELAR
    </button>
    <button
      *ngIf="!goalId"
      routerLink="../"
      class="mat-elevation-z4"
      mat-raised-button
      color="warn"
      extended
      matTooltip="Cancelar as informações preenchidas e voltar a página"
    >
      <mat-icon>block</mat-icon> CANCELAR
    </button>
  </mat-card-actions>

  <mat-card-footer>
    <mat-progress-bar mode="indeterminate" *ngIf="isSaving"></mat-progress-bar>
  </mat-card-footer>
</mat-card>
