<div class="container mt-5" *ngIf="isUser">
  <div class="row">
    <div class="col-md-4 mt-md-0 mt-sm-4">
      <h2>Rotinas:</h2>
      <ul class="list-group minimal-list">
        <li *ngFor="let item of items" class="list-group-item">
          <div class="d-flex justify-content-between align-items-center">
            <span>{{ item.name }}</span>
            <span class="badge bg-primary rounded-pill">{{ item.value }}</span>
          </div>
        </li>
      </ul>
    </div>

    <div class="col-md-4 mt-md-0 mt-sm-4">
      <h2>Tarefas:</h2>
      <ul class="list-group minimal-list">
        <li *ngFor="let item of items" class="list-group-item">
          <div class="d-flex justify-content-between align-items-center">
            <span>{{ item.name }}</span>
            <span class="badge bg-primary rounded-pill">{{ item.value }}</span>
          </div>
        </li>
      </ul>
    </div>

    <div class="col-md-4 mt-md-0 mt-sm-4">
      <h2>Metas:</h2>
      <ul class="list-group minimal-list">
        <li *ngFor="let item of items" class="list-group-item">
          <div class="d-flex justify-content-between align-items-center">
            <span>{{ item.name }}</span>
            <span class="badge bg-primary rounded-pill">{{ item.value }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="dashboard-navigation">
  <div class="nav-dashboard-card" *ngIf="isSupervisor">
    <h3>Avaliacões na Semana</h3>
    <canvas baseChart
          [datasets]="barChartData"
          [labels]="barChartLabels"
          [options]="{ responsive: true }"
          [legend]="barChartLegend"
          [type]="barChartType">
    </canvas>
  </div>

  <div class="nav-dashboard-card" *ngIf="isSupervisor">
    <h3>Avaliacões por Mês</h3>
    <canvas baseChart
            [datasets]="lineChartData"
            [labels]="lineChartLabels"
            [options]="{ responsive: true }"
            [legend]="lineChartLegend"
            [type]="lineChartType">
    </canvas>
  </div>
</div>

<div class="home-navigation">
  <div class="nav-card" *ngIf="isSupervisor">
    <i class="fas fa-users"></i>
    <h3>Funcionários</h3>
    <a href="/employees" class="btn">Acessar</a>
  </div>

  <div class="nav-card" *ngIf="isSupervisor">
    <i class="fas fa-user-tie"></i>
    <h3>Profissionais</h3>
    <a href="/professionals" class="btn">Acessar</a>
  </div>

  <div class="nav-card" *ngIf="isSupervisor">
    <i class="fas fa-truck-field"></i>
    <h3>Fornecedores</h3>
    <a href="/suppliers" class="btn">Acessar</a>
  </div>

  <div class="nav-card" *ngIf="isAdmin">
    <i class="fas fa-chart-line"></i>
    <h3>Segmentos</h3>
    <a href="/segments" class="btn">Acessar</a>
  </div>

  <div class="nav-card" *ngIf="isAdmin">
    <i class="fas fa-chart-line"></i>
    <h3>Holdings</h3>
    <a href="/holding" class="btn">Acessar</a>
  </div>

  <div class="nav-card" *ngIf="isAdmin">
    <i class="fas fa-chart-line"></i>
    <h3>Empresas</h3>
    <a href="/company" class="btn">Acessar</a>
  </div>

  <div class="nav-card" *ngIf="isAdmin">
    <i class="fas fa-chart-line"></i>
    <h3>Unidades</h3>
    <a href="/offices" class="btn">Acessar</a>
  </div>

  <div class="nav-card" *ngIf="isAdmin">
    <i class="fas fa-chart-line"></i>
    <h3>Funções</h3>
    <a href="/responsibilities" class="btn">Acessar</a>
  </div>

  <div class="nav-card" *ngIf="isSupervisor">
    <i class="fas fa-suitcase"></i>
    <h3>Cargos</h3>
    <a href="/responsibilities" class="btn">Acessar</a>
  </div>

  <div class="nav-card" *ngIf="isSupervisor">
    <i class="fas fa-bullseye"></i>
    <h3>Metas</h3>
    <a href="/goals" class="btn">Acessar</a>
  </div>

  <div class="nav-card" *ngIf="isSupervisor">
    <i class="fas fa-list-check"></i>
    <h3>Tarefas</h3>
    <a href="/tasks" class="btn">Acessar</a>
  </div>

  <div class="nav-card" *ngIf="isSupervisor">
    <i class="fas fa-briefcase"></i>
    <h3>Rotinas</h3>
    <a href="/routines" class="btn">Acessar</a>
  </div>

  <div class="nav-card" *ngIf="isSupervisor || isUser">
    <i class="fas fa-folder-open"></i>
    <h3>Documentos</h3>
    <a href="/document" class="btn">Acessar</a>
  </div>

  <div class="nav-card" *ngIf="isSupervisor">
    <i class="fas fa-chart-pie"></i>
    <h3>Relatórios</h3>
    <a href="/reports" class="btn">Acessar</a>
  </div>

  <!-- <div class="nav-card" *ngIf="isSupervisor">
    <i class="fas fa-chart-simple"></i>
    <h3>Dashboards</h3>
    <a href="/dashboards" class="btn">Acessar</a>
  </div> -->
</div>
