<!-- Tarefas Modal -->
<div class="modal fade" id="tasksModal" tabindex="-1" aria-labelledby="tasksModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" style="max-width: fit-content;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h2>Tarefas:</h2>
        <p>Todas as tarefas atribuídas para {{ person.name }}.</p>

        <div class="mt-md-0 mt-sm-4" *ngIf="tasks.length != 0">
          <ul class="list-group minimal-list">
            <li *ngFor="let task of tasks" class="list-group-item">
              <div class="d-flex justify-content-between align-items-center gap-2" style="flex-wrap: nowrap;">
                <span class="text-nowrap">{{ task.title }}</span>
                <span class="badge bg-primary rounded-pill text-nowrap">Início: {{ task.startDate | dateFormat }}</span>
                <span class="badge bg-primary rounded-pill text-nowrap">Fim: {{ task.endDate | dateFormat }}</span>
                <button class="btn btn-outline-warning btn-round" (click)="onEditTask(task)">
                  <i class="fas fa-pencil-alt"></i>
                </button>
                <button class="btn btn-outline-danger btn-round" (click)="onDeleteTask(task.id)">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </li>
          </ul>
        </div>

        <div class="mt-md-0 mt-sm-4" *ngIf="tasks.length == 0">
          <img src="../../../../../assets/img/folder.png" alt="empty" class="empty" />
          <h4>Nenhuma tarefa cadastrada.</h4>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#tasksCreateModal2">
          Cadastrar
        </button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
          Fechar
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="tasksCreateModal2" tabindex="-1" aria-labelledby="tasksCreateModalLabel2" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" style="max-width: fit-content;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h2>Nova Tarefa:</h2>
        <p>Cadastre uma nova tarefa para {{ person.name }}.</p>

        <form [formGroup]="formTask" (ngSubmit)="onSubmitFormTask()">
          <div class="mb-3">
            <label for="title" class="form-label">Título</label>
            <input type="text" class="form-control" id="title" formControlName="title" placeholder="Digite o título" />
          </div>
          <div class="mb-3">
            <label for="taskDescription" class="form-label">Descrição</label>
            <textarea class="form-control" id="taskDescription" rows="3" formControlName="description" placeholder="Digite sua descrição aqui"></textarea>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="taskStartDate" class="form-label">Data de Início</label>
              <input type="date" class="form-control" id="taskStartDate" formControlName="startDate">
            </div>
            <div class="col-md-6 mb-3">
              <label for="taskEndDate" class="form-label">Data de Fim</label>
              <input type="date" class="form-control" id="taskEndDate" formControlName="endDate">
            </div>
          </div>
          <button *ngIf="!isSavingTask" type="submit" class="btn btn-success w-100">Salvar</button>
        </form>
        <div *ngIf="isSavingTask" class="spinner-container">
          <div class="spinner"></div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="closeTaskCreateModal()">Fechar</button>
      </div>
    </div>
  </div>
</div>

<!-- Metas Modal -->
<div class="modal fade" id="goalsModal" tabindex="-1" aria-labelledby="goalsModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" style="max-width: fit-content;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h2>Metas:</h2>
        <p>Todas as metas atribuídas para {{ person.name }}.</p>

        <div class="mt-md-0 mt-sm-4" *ngIf="goals.length != 0">
          <ul class="list-group minimal-list">
            <li *ngFor="let goal of goals" class="list-group-item">
              <div class="d-flex justify-content-between align-items-center gap-2" style="flex-wrap: nowrap;">
                <span class="text-nowrap">{{ goal.title }}</span>
                <span class="badge bg-primary rounded-pill text-nowrap">Início: {{ goal.startDate | dateFormat }}</span>
                <span class="badge bg-primary rounded-pill text-nowrap">Fim: {{ goal.endDate | dateFormat }}</span>
                <button class="btn btn-outline-warning btn-round" (click)="onEditGoal(goal)">
                  <i class="fas fa-pencil-alt"></i>
                </button>
                <button class="btn btn-outline-danger btn-round" (click)="onDeleteGoal(goal.id)">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </li>
          </ul>
        </div>

        <div class="mt-md-0 mt-sm-4" *ngIf="goals.length == 0">
          <img src="../../../../../assets/img/folder.png" alt="empty" class="empty" />
          <h4>Nenhuma meta cadastrada.</h4>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#goalsCreateModal2">
          Cadastrar
        </button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
          Fechar
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="goalsCreateModal2" tabindex="-1" aria-labelledby="goalsCreateModalLabel2" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" style="max-width: fit-content;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h2>Nova Tarefa:</h2>
        <p>Cadastre uma nova tarefa para {{ person.name }}.</p>

        <form [formGroup]="formGoal" (ngSubmit)="onSubmitFormGoal()">
          <div class="mb-3">
            <label for="title" class="form-label">Título</label>
            <input type="text" class="form-control" id="title" formControlName="title" placeholder="Digite o título" />
          </div>
          <div class="mb-3">
            <label for="goalDescription" class="form-label">Descrição</label>
            <textarea class="form-control" id="goalDescription" rows="3" formControlName="description" placeholder="Digite sua descrição aqui"></textarea>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="goalStartDate" class="form-label">Data de Início</label>
              <input type="date" class="form-control" id="goalStartDate" formControlName="startDate">
            </div>
            <div class="col-md-6 mb-3">
              <label for="goalEndDate" class="form-label">Data de Fim</label>
              <input type="date" class="form-control" id="goalEndDate" formControlName="endDate">
            </div>
          </div>
          <button *ngIf="!isSavingGoal" type="submit" class="btn btn-success w-100">Salvar</button>
        </form>
        <div *ngIf="isSavingGoal" class="spinner-container">
          <div class="spinner"></div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="closeGoalCreateModal()">Fechar</button>
      </div>
    </div>
  </div>
</div>

<!-- Appointments Modal -->
<div class="modal fade" id="appointmentsModal" tabindex="-1" aria-labelledby="appointmentsModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" style="max-width: fit-content;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div *ngIf="activitiesResponse.length > 0">
          <h2>Avaliações:</h2>

          <div class="mt-md-0 mt-sm-4">
            <ul class="list-group minimal-list">
              <li *ngFor="let activity of activitiesResponse" class="list-group-item spacing-item">
                <div class="d-flex justify-content-between align-items-center gap-5" style="flex-wrap: nowrap;">
                  <span class="text-nowrap">{{ activity.name }}</span>
                  <div class="radio-group">
                    <!-- <input
                      *ngFor="let tag of tags;"
                      type="radio"
                      [name]="'daily' + activity.id"
                      value="{{ tag.id }}"
                      [ngClass]="'radio-button_' + tag.class"
                      [checked]="tag.id == activity.tag?.id"
                      (click)="openAppointmentCreateModal(activity, tag)"
                      matTooltip="{{ tag.description }}"
                    /> -->
                    <div
                      *ngFor="let tag of tags;"
                      [ngClass]="['radio-button_' + tag.class, tag.id === activity.tag?.id ? 'checked' : '']"
                      (click)="openAppointmentCreateModal(activity, tag)"
                      [class.checked]="tag.id === activity.tag?.id"
                      matTooltip="{{ tag.description }}"
                    ></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div *ngIf="activitiesResponse.length == 0" class="mt-4">
          <img src="../../../../../assets/img/empty-box.png" alt="empty" class="empty" />
          <h4>Nenhuma rotina cadastrada para o cargo.</h4>
        </div>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#appointmentsCreateModal">
          Cadastrar
        </button> -->
        <button class="btn btn-primary" (click)="router.navigate(['/employees/create'])">
          Cadastrar Nova Rotina
        </button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
          Fechar
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="appointmentsCreateModal" tabindex="-1" aria-labelledby="appointmentsCreateModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" style="max-width: fit-content;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h2>Avaliação:</h2>
        <p>Cadastre uma nova avaliação para {{ person.name }}.</p>

        <form [formGroup]="formAppointment" (ngSubmit)="onSubmitFormAppointment()">
          <div class="mb-3">
            <label for="description" class="form-label">Observação</label>
            <textarea class="form-control" id="description" rows="3" formControlName="description" placeholder="Digite a sua observaçao aqui"></textarea>
          </div>
          <div class="mb-3">
            <label for="justification" class="form-label">Justificativa</label>
            <textarea class="form-control" id="justification" rows="3" formControlName="justification" placeholder="Digite a justificativa do funcionário aqui"></textarea>
          </div>
          <button *ngIf="!isSavingTask" type="submit" class="btn btn-success w-100">Salvar</button>
        </form>
        <div *ngIf="isSavingTask" class="spinner-container">
          <div class="spinner"></div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="closeAppointmentCreateModal()">Fechar</button>
      </div>
    </div>
  </div>
</div>

<!-- Atestados Médicos Modal -->
<div class="modal fade" id="abstinencesModal" tabindex="-1" aria-labelledby="abstinencesModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" style="max-width: fit-content;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h2>Atestados Médico:</h2>
        <p>Todas os atestados médicos atribuídos para {{ person.name }}.</p>

        <div class="mt-md-0 mt-sm-4">
          <ul class="list-group minimal-list">
            <li *ngFor="let abstinence of abstinences" class="list-group-item">
              <div class="d-flex justify-content-between align-items-center gap-2" style="flex-wrap: nowrap;">
                <span class="text-nowrap">{{ abstinence.title }}</span>
                <span class="badge bg-primary rounded-pill text-nowrap">Início: {{ abstinence.startDate | dateFormat }}</span>
                <span class="badge bg-primary rounded-pill text-nowrap">Fim: {{ abstinence.endDate | dateFormat }}</span>
                <button class="btn btn-outline-warning btn-round" (click)="onEditAbstinence(abstinence)"><i class="fas fa-pencil-alt"></i></button>
                <button class="btn btn-outline-danger btn-round" (click)="onDeleteAbstinence(abstinence.id)"><i class="fas fa-trash-alt"></i></button>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#abstinencesCreateModal">
          Cadastrar
        </button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
          Fechar
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="abstinencesCreateModal" tabindex="-1" aria-labelledby="abstinencesCreateModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h2>Novo Atestado Médico:</h2>
        <p>Cadastre um novo atestado para {{ person.name }}.</p>

        <form [formGroup]="formAbstinence" (ngSubmit)="onSubmitFormAbstinence()">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="abstinenceStartDate" class="form-label">Data de Início</label>
              <input type="date" class="form-control" id="abstinenceStartDate" formControlName="startDate">
            </div>
            <div class="col-md-6 mb-3">
              <label for="abstinenceEndDate" class="form-label">Data de Fim</label>
              <input type="date" class="form-control" id="abstinenceEndDate" formControlName="endDate">
            </div>
            <div class="mb-3">
              <label for="description" class="form-label">Observação</label>
              <textarea class="form-control" id="description" rows="5" formControlName="description" placeholder="Digite a observação"></textarea>
            </div>
            <!-- TODO: adicionar o documento no atestado médico -->
            <!-- <div class="mb-3">
              <label for="description" class="form-label">Documento</label>
              <input type="file" id="picture" formControlName="picture" class="form-control" (change)="onFileSelected($event)"
                   [ngClass]="{'is-invalid': formAbstinence.get('picture')?.invalid && formAbstinence.get('document')?.touched,
                   'is-valid': formAbstinence.get('document')?.valid && formAbstinence.get('document')?.touched}">
            </div> -->
          </div>
          <button *ngIf="!isSavingAbstinence" type="submit" class="btn btn-success w-100">Salvar</button>
        </form>
        <div *ngIf="isSavingAbstinence" class="spinner-container">
          <div class="spinner"></div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="closeAbstinenceCreateModal()">Fechar</button>
      </div>
    </div>
  </div>
</div>

<!-- Férias -->
<div class="modal fade" id="vacationsModal" tabindex="-1" aria-labelledby="vacationsModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" style="max-width: fit-content;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h2>Férias:</h2>
        <p>Todas as férias atribuídas para {{ person.name }}.</p>

        <div class="mt-md-0 mt-sm-4">
          <ul class="list-group minimal-list">
            <li *ngFor="let task of tasks" class="list-group-item">
              <div class="d-flex justify-content-between align-items-center gap-2" style="flex-wrap: nowrap;">
                <span class="text-nowrap">{{ task.title }}</span>
                <span class="badge bg-primary rounded-pill text-nowrap">Início: {{ task.startDate | dateFormat }}</span>
                <span class="badge bg-primary rounded-pill text-nowrap">Fim: {{ task.endDate | dateFormat }}</span>
                <button class="btn btn-outline-warning btn-round" (click)="onEditTask(task)"><i class="fas fa-pencil-alt"></i></button>
                <button class="btn btn-outline-danger btn-round" (click)="onDeleteTask(task.id)"><i class="fas fa-trash-alt"></i></button>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#vacationsCreateModal">
          Cadastrar
        </button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
          Fechar
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="vacationsCreateModal" tabindex="-1" aria-labelledby="vacationsCreateModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h2>Novas Férias:</h2>
        <p>Cadastre uma nova férias para {{ person.name }}.</p>

        <form [formGroup]="formVacation" (ngSubmit)="onSubmitFormVacation()">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="vacationStartDate" class="form-label">Data de Início</label>
              <input type="date" class="form-control" id="vacationStartDate" formControlName="startDate">
            </div>
            <div class="col-md-6 mb-3">
              <label for="vacationEndDate" class="form-label">Data de Fim</label>
              <input type="date" class="form-control" id="vacationEndDate" formControlName="endDate">
            </div>
            <div class="mb-3">
              <label for="description" class="form-label">Observação</label>
              <textarea class="form-control" id="description" rows="5" formControlName="description" placeholder="Digite a observação"></textarea>
            </div>
          </div>
          <button *ngIf="!isSavingVacation" type="submit" class="btn btn-success w-100">Salvar</button>
        </form>
        <div *ngIf="isSavingVacation" class="spinner-container">
          <div class="spinner"></div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="closeVacationCreateModal()">Fechar</button>
      </div>
    </div>
  </div>
</div>







<div class="container mt-4">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
      <!-- <li class="breadcrumb-item"><a routerLink="/employees">Funcionários</a></li> -->
      <li class="breadcrumb-item active" aria-current="page">Tabela de Avaliaçoes</li>
    </ol>
  </nav>

  <div class="row">
    <!-- Primeira Coluna -->
    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <h2 class="card-title">📓 Registrar Ocorrência</h2>
          <p *ngIf="person.contractType == 'Autônomo'" class="card-text mt-2">
            Profissional: <strong>{{ person.name }}</strong>
          </p>
          <p *ngIf="person.contractType == 'Clt'" class="card-text mt-2">
            Funcionário: <strong>{{ person.name }}</strong>
          </p>
          <p *ngIf="person.contractType == 'Fornecedor'" class="card-text mt-2">
            Fornecedor: <strong>{{ person.name }}</strong>
          </p>
          <p class="card-text">
            Empresa: <strong>{{ person.officeFantasyName }}</strong>
          </p>
          <p class="card-text">
            Dia: <strong>{{ today }}</strong>
          </p>
          <p class="card-text">
            <img [lazyLoad]="person.picture" [defaultImage]="s3DefaultImage" alt="" width="200" height="200" />
          </p>
          <p class="card-text">
            <button class="btn btn-link btn-sm me-1" (click)="openAbstinencesModal()">Atestado Médico</button>
            <button class="btn btn-link btn-sm me-1" (click)="openVacationsModal()">Férias</button>
          </p>
          <p class="card-text gap-2 mb-2">
            <button class="btn btn-options btn-primary me-2 mb-2" (click)="openTasksModal()">Tarefas</button>
            <button class="btn btn-options btn-primary me-2 mb-2" (click)="openGoalsModal()">Metas</button>
            <button class="btn btn-options btn-primary me-2 mb-2" (click)="openTasksModal()">Manual</button>
            <button class="btn btn-options btn-primary me-2 mb-2" (click)="openTasksModal()">Código de Ética</button>
          </p>
        </div>
      </div>
    </div>

      <!-- Segunda Coluna -->
    <div class="col-md-8">
      <div class="card">
        <div class="card-body">
          <mat-tab-group [selectedIndex]="selectedTab.value" (selectedIndexChange)="selectedTab.setValue($event)">
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon *ngIf="selectedTab.value == 0" class="example-tab-icon">arrow_right</mat-icon> Diária
              </ng-template>

              <section class="monthly-container mat-elevation-z2" tabindex="0">
                <ul class="list-group minimal-list">
                  <div *ngIf="activitiesDailyResponse.length > 0">
                    <li class="list-group-item">
                      <div class="d-flex justify-content-between align-items-center">
                        <span class="text-column"></span>
                        <div class="radio-group">
                          <!-- <input
                            *ngFor="let option of options;"
                            type="radio"
                            [name]="option.index"
                            [value]="option"
                            [id]="'radio' + option.index"
                            [class]="'radio-' + option.color"
                            matTooltip="{{ option.description }}"
                            checked
                          /> -->
                          <div
                            *ngFor="let option of options;"
                            [ngClass]="['radio-button_' + option.color + '-background', 'checked']"
                            matTooltip="{{ option.description }}"
                          ></div>
                        </div>
                      </div>
                    </li>
                    <li *ngFor="let activity of activitiesDailyResponse" class="list-group-item">
                      <div class="d-flex justify-content-between align-items-center">
                        <span class="text-column">{{ activity.name }}</span>
                        <div class="radio-group">
                          <!-- <input
                            *ngFor="let tag of tags;"
                            type="radio"
                            [name]="'daily' + activity.id"
                            value="{{ tag.id }}"
                            [ngClass]="'radio-button_' + tag.class"
                            [checked]="tag.id == activity.tag?.id"
                            (click)="openAppointmentCreateModal(activity, tag)"
                            matTooltip="{{ tag.description }}"
                          /> -->
                          <div
                            *ngFor="let tag of tags;"
                            [ngClass]="['radio-button_' + tag.class, tag.id === activity.tag?.id ? 'checked' : '']"
                            (click)="openAppointmentCreateModal(activity, tag)"
                            [class.checked]="tag.id === activity.tag?.id"
                            matTooltip="{{ tag.description }}"
                          ></div>
                        </div>
                      </div>
                    </li>
                  </div>

                  <div *ngIf="activitiesDailyResponse.length == 0" class="mt-4">
                    <img src="../../../../../assets/img/empty-box.png" alt="empty" class="empty" />
                    <h4>Nenhuma rotina cadastrada para o cargo.</h4>
                    <button class="btn btn-primary" (click)="router.navigate(['/employees/create'])">
                      Cadastrar Nova Rotina
                    </button>
                  </div>
                </ul>
              </section>
            </mat-tab>

            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon *ngIf="selectedTab.value == 1">arrow_right</mat-icon> Mensal
              </ng-template>
              <div class="grid-container">
                <div>
                  <mat-calendar [(selected)]="selected" [dateClass]="dateClassCurrentMonth" (click)="openAppointmentModal()" [minDate]="firstDay" [maxDate]="lastDay"></mat-calendar>
                </div>
              </div>
            </mat-tab>

            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon *ngIf="selectedTab.value == 2">arrow_right</mat-icon> Trimestral
              </ng-template>
              <div class="grid-container">
                <div>
                  <mat-calendar [(selected)]="selected" [dateClass]="dateClassLastTwoMonth" (click)="openAppointmentModal()" [minDate]="firstDayLastTwoMonth" [maxDate]="lastDayLastTwoMonth"></mat-calendar>
                </div>
                <div>
                  <mat-calendar [(selected)]="selected" [dateClass]="dateClassLastMonth" (click)="openAppointmentModal()" [minDate]="firstDayLastMonth" [maxDate]="lastDayLastMonth"></mat-calendar>
                </div>
                <div>
                  <mat-calendar [(selected)]="selected" [dateClass]="dateClassCurrentMonth" (click)="openAppointmentModal()" [minDate]="firstDay" [maxDate]="lastDay"></mat-calendar>
                </div>
              </div>
            </mat-tab>

            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon *ngIf="selectedTab.value == 3" class="example-tab-icon">arrow_right</mat-icon> Semestral
              </ng-template>
              <div class="grid-container">
                <div>
                  <mat-calendar [(selected)]="selected" [dateClass]="dateClassLastFiveMonth" (click)="openAppointmentModal()" [minDate]="firstDayLastFiveMonth" [maxDate]="lastDayLastFiveMonth"></mat-calendar>
                </div>
                <div>
                  <mat-calendar [(selected)]="selected" [dateClass]="dateClassLastFourMonth" (click)="openAppointmentModal()" [minDate]="firstDayLastFourMonth" [maxDate]="lastDayLastFourMonth"></mat-calendar>
                </div>
                <div>
                  <mat-calendar [(selected)]="selected" [dateClass]="dateClassLastThreeMonth" (click)="openAppointmentModal()" [minDate]="firstDayLastThreeMonth" [maxDate]="lastDayLastThreeMonth"></mat-calendar>
                </div>
              </div>
              <div class="grid-container">
                <div>
                  <mat-calendar [(selected)]="selected" [dateClass]="dateClassLastTwoMonth" (click)="openAppointmentModal()" [minDate]="firstDayLastTwoMonth" [maxDate]="lastDayLastTwoMonth"></mat-calendar>
                </div>
                <div>
                  <mat-calendar [(selected)]="selected" [dateClass]="dateClassLastMonth" (click)="openAppointmentModal()" [minDate]="firstDayLastMonth" [maxDate]="lastDayLastMonth"></mat-calendar>
                </div>
                <div>
                  <mat-calendar [(selected)]="selected" [dateClass]="dateClassCurrentMonth" (click)="openAppointmentModal()" [minDate]="firstDay" [maxDate]="lastDay"></mat-calendar>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</div>
















<!-- <div class="container mt-4">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/employees">Funcionários</a></li>
      <li class="breadcrumb-item active" aria-current="page">Tabela de Avaliaçoes</li>
    </ol>
  </nav>

  <div class="row mb-3 mt-4">
    <div class="col-md-6">
      <div class="col-md-12">
        <h2>📓 Registrar Ocorrência</h2>
      </div>
      <div class="col-md-12">
        <span>Funcionário - {{ person.name }}</span>
      </div>
      <div class="col-md-12">
        <span>Dia: {{ today }}</span>
      </div>
      <div class="col-md-12 mt-3 mb-3">
        <button class="btn btn-options btn-primary me-2" (click)="openTasksModal()">Tarefas</button>
        <button class="btn btn-options btn-primary me-2" (click)="openGoalsModal()">Metas</button>
        <button class="btn btn-options btn-primary me-2" (click)="openTasksModal()">Manual</button>
        <button class="btn btn-options btn-primary me-2" (click)="openTasksModal()">Código de Ética</button>
      </div>
      <div class="col-md-12">
        <img [lazyLoad]="person.picture" [defaultImage]="s3DefaultImage" alt="" width="200" height="200" />
      </div>
      <div class="col-md-12 mt-4">
        <span>Nome: {{ person.name }}</span>
      </div>
      <div class="col-md-12 mt-2">
        <span>Empresa: {{ person.office.fantasyName }}</span>
      </div>
    </div>



    <div class="col-md-12 mt-4">
      <mat-tab-group [selectedIndex]="selectedTab.value" (selectedIndexChange)="selectedTab.setValue($event)">
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon *ngIf="selectedTab.value == 0" class="example-tab-icon">arrow_right</mat-icon> Diária
          </ng-template>

          <section class="monthly-container mat-elevation-z2" tabindex="0">
            <ul class="list-group minimal-list">
              <li class="list-group-item">
                <div class="d-flex justify-content-between align-items-center">
                  <span class="text-column"></span>
                  <div class="radio-group">
                    <input *ngFor="let option of options;" type="radio" [name]="option.index" [value]="option" [id]="'radio' + option.index" [class]="'radio-' + option.color" checked />
                  </div>
                </div>
              </li>

              <li *ngFor="let activity of activitiesDailyResponse" class="list-group-item">
                <div class="d-flex justify-content-between align-items-center">
                  <span class="text-column">{{ activity.name }}</span>
                  <div class="radio-group">
                    <input *ngFor="let tag of tags;" type="radio" [name]="'daily' + activity.id" value="{{ tag.id }}" [ngClass]="'radio-button_' + tag.class" [checked]="tag.id == activity.tag?.id" (click)="openAppointmentCreateModal(activity, tag)" matTooltip="{{ tag.description }}" />
                  </div>
                </div>
              </li>
            </ul>
          </section>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon *ngIf="selectedTab.value == 1">arrow_right</mat-icon> Mensal
          </ng-template>
          <div class="grid-container">
            <div>
              <mat-calendar [(selected)]="selected" [dateClass]="dateClassCurrentMonth" (click)="openAppointmentModal()" [minDate]="firstDay" [maxDate]="lastDay"></mat-calendar>
            </div>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon *ngIf="selectedTab.value == 2">arrow_right</mat-icon> Trimestral
          </ng-template>
          <div class="grid-container">
            <div>
              <mat-calendar [(selected)]="selected" [dateClass]="dateClassLastTwoMonth" (click)="openDialog()" [minDate]="firstDayLastTwoMonth" [maxDate]="lastDayLastTwoMonth"></mat-calendar>
            </div>
            <div>
              <mat-calendar [(selected)]="selected" [dateClass]="dateClassLastMonth" (click)="openDialog()" [minDate]="firstDayLastMonth" [maxDate]="lastDayLastMonth"></mat-calendar>
            </div>
            <div>
              <mat-calendar [(selected)]="selected" [dateClass]="dateClassCurrentMonth" (click)="openDialog()" [minDate]="firstDay" [maxDate]="lastDay"></mat-calendar>
            </div>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon *ngIf="selectedTab.value == 3" class="example-tab-icon">arrow_right</mat-icon> Semestral
          </ng-template>
          <div class="grid-container">
            <div>
              <mat-calendar [(selected)]="selected" [dateClass]="dateClassLastFiveMonth" (click)="openDialog()" [minDate]="firstDayLastFiveMonth" [maxDate]="lastDayLastFiveMonth"></mat-calendar>
            </div>
            <div>
              <mat-calendar [(selected)]="selected" [dateClass]="dateClassLastFourMonth" (click)="openDialog()" [minDate]="firstDayLastFourMonth" [maxDate]="lastDayLastFourMonth"></mat-calendar>
            </div>
            <div>
              <mat-calendar [(selected)]="selected" [dateClass]="dateClassLastThreeMonth" (click)="openDialog()" [minDate]="firstDayLastThreeMonth" [maxDate]="lastDayLastThreeMonth"></mat-calendar>
            </div>
          </div>
          <div class="grid-container">
            <div>
              <mat-calendar [(selected)]="selected" [dateClass]="dateClassLastTwoMonth" (click)="openDialog()" [minDate]="firstDayLastTwoMonth" [maxDate]="lastDayLastTwoMonth"></mat-calendar>
            </div>
            <div>
              <mat-calendar [(selected)]="selected" [dateClass]="dateClassLastMonth" (click)="openDialog()" [minDate]="firstDayLastMonth" [maxDate]="lastDayLastMonth"></mat-calendar>
            </div>
            <div>
              <mat-calendar [(selected)]="selected" [dateClass]="dateClassCurrentMonth" (click)="openDialog()" [minDate]="firstDay" [maxDate]="lastDay"></mat-calendar>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div> -->











<!--<mat-card class="card mat-elevation-z8">-->
<!--  <div class="wrapper">-->
<!--    <div class="item">-->
<!--      <mat-card-header>-->
<!--        <mat-card-title>Registrar Ocorrência</mat-card-title>-->
<!--        <mat-card-subtitle>Funcionário - {{ person.name }}</mat-card-subtitle>-->
<!--        <mat-card-subtitle>-->
<!--          <button mat-raised-button (click)="openTasksModal()">Tarefas</button>-->
<!--          <button mat-raised-button data-bs-toggle="modal" data-bs-target="#goalsModal">Metas</button>-->
<!--        </mat-card-subtitle>-->

<!--        <mat-card-subtitle><strong>Dia: {{ today }}</strong>-->
<!--        </mat-card-subtitle>-->
<!--      </mat-card-header>-->

<!--      <div class="grid-first-container">-->
<!--        <mat-tab-group dynamicHeight [selectedIndex]="selectedTab.value" (selectedIndexChange)="selectedTab.setValue($event)">-->
<!--          <mat-tab>-->
<!--            <ng-template mat-tab-label>-->
<!--              <mat-icon *ngIf="selectedTab.value == 0" class="example-tab-icon">arrow_right</mat-icon> Diária-->
<!--            </ng-template>-->

<!--            <section class="monthly-container mat-elevation-z2" tabindex="0">-->
<!--              <ul class="list-group minimal-list">-->
<!--                <li class="list-group-item">-->
<!--                  <div class="d-flex justify-content-between align-items-center">-->
<!--                    <span class="text-column"></span>-->
<!--                    <div class="radio-group">-->
<!--                      <input *ngFor="let option of options;" type="radio" [name]="option.index" [value]="option" [id]="'radio' + option.index" [class]="'radio-' + option.color" checked />-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </li>-->

<!--                <li *ngFor="let activity of activitiesDailyResponse" class="list-group-item">-->
<!--                  <div class="d-flex justify-content-between align-items-center">-->
<!--                    <span class="text-column">{{ activity.name }}</span>-->
<!--                    <div class="radio-group">-->
<!--                      <input *ngFor="let tag of tags;" type="radio" [name]="'daily' + activity.id" value="{{ tag.id }}" [ngClass]="'radio-button_' + tag.class" [checked]="tag.id == activity.tag?.id" (click)="openAppointmentCreateModal(activity, tag)" matTooltip="{{ tag.description }}" />-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </section>-->
<!--          </mat-tab>-->

<!--          <mat-tab>-->
<!--            <ng-template mat-tab-label>-->
<!--              <mat-icon *ngIf="selectedTab.value == 1">arrow_right</mat-icon> Mensal-->
<!--            </ng-template>-->
<!--            <div class="grid-container">-->
<!--              <div>-->
<!--                <mat-calendar [(selected)]="selected" [dateClass]="dateClassCurrentMonth" (click)="openAppointmentModal()" [minDate]="firstDay" [maxDate]="lastDay"></mat-calendar>-->
<!--              </div>-->
<!--            </div>-->
<!--          </mat-tab>-->

<!--          <mat-tab>-->
<!--            <ng-template mat-tab-label>-->
<!--              <mat-icon *ngIf="selectedTab.value == 2">arrow_right</mat-icon> Trimestral-->
<!--            </ng-template>-->
<!--            <div class="grid-container">-->
<!--              <div>-->
<!--                <mat-calendar [(selected)]="selected" [dateClass]="dateClassLastTwoMonth" (click)="openDialog()" [minDate]="firstDayLastTwoMonth" [maxDate]="lastDayLastTwoMonth"></mat-calendar>-->
<!--              </div>-->
<!--              <div>-->
<!--                <mat-calendar [(selected)]="selected" [dateClass]="dateClassLastMonth" (click)="openDialog()" [minDate]="firstDayLastMonth" [maxDate]="lastDayLastMonth"></mat-calendar>-->
<!--              </div>-->
<!--              <div>-->
<!--                <mat-calendar [(selected)]="selected" [dateClass]="dateClassCurrentMonth" (click)="openDialog()" [minDate]="firstDay" [maxDate]="lastDay"></mat-calendar>-->
<!--              </div>-->
<!--            </div>-->
<!--          </mat-tab>-->

<!--          <mat-tab>-->
<!--            <ng-template mat-tab-label>-->
<!--              <mat-icon *ngIf="selectedTab.value == 3" class="example-tab-icon">arrow_right</mat-icon> Semestral-->
<!--            </ng-template>-->
<!--            <div class="grid-container">-->
<!--              <div>-->
<!--                <mat-calendar [(selected)]="selected" [dateClass]="dateClassLastFiveMonth" (click)="openDialog()" [minDate]="firstDayLastFiveMonth" [maxDate]="lastDayLastFiveMonth"></mat-calendar>-->
<!--              </div>-->
<!--              <div>-->
<!--                <mat-calendar [(selected)]="selected" [dateClass]="dateClassLastFourMonth" (click)="openDialog()" [minDate]="firstDayLastFourMonth" [maxDate]="lastDayLastFourMonth"></mat-calendar>-->
<!--              </div>-->
<!--              <div>-->
<!--                <mat-calendar [(selected)]="selected" [dateClass]="dateClassLastThreeMonth" (click)="openDialog()" [minDate]="firstDayLastThreeMonth" [maxDate]="lastDayLastThreeMonth"></mat-calendar>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="grid-container">-->
<!--              <div>-->
<!--                <mat-calendar [(selected)]="selected" [dateClass]="dateClassLastTwoMonth" (click)="openDialog()" [minDate]="firstDayLastTwoMonth" [maxDate]="lastDayLastTwoMonth"></mat-calendar>-->
<!--              </div>-->
<!--              <div>-->
<!--                <mat-calendar [(selected)]="selected" [dateClass]="dateClassLastMonth" (click)="openDialog()" [minDate]="firstDayLastMonth" [maxDate]="lastDayLastMonth"></mat-calendar>-->
<!--              </div>-->
<!--              <div>-->
<!--                <mat-calendar [(selected)]="selected" [dateClass]="dateClassCurrentMonth" (click)="openDialog()" [minDate]="firstDay" [maxDate]="lastDay"></mat-calendar>-->
<!--              </div>-->
<!--            </div>-->
<!--          </mat-tab>-->
<!--        </mat-tab-group>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="item">-->
<!--      <div class="column-container">-->
<!--        <div class="row-item">-->
<!--          <img [lazyLoad]="person.picture" [defaultImage]="s3DefaultImage" alt="" width="200" height="200" />-->
<!--          <h2>Nome: {{ person.name }}</h2>-->
<!--          <h2>Empresa: {{ person.office.fantasyName }}</h2>-->
<!--          <h2>Função: {{ person.responsibility.name }}</h2>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</mat-card>-->
