<div class="container mt-4">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Tarefas</li>
    </ol>
  </nav>

  <h1>Lista de Tarefas</h1>

  <div *ngIf="loading" class="alert alert-info">
    Carregando tarefas...
  </div>

  <app-goal-task-table
    *ngIf="!loading"
    [headers]="taskHeaders"
    [data]="taskData"
    [itemsPerPage]="5"
     (finish)="onFinish($event)"
     (edit)="onEdit($event)"
     (delete)="onDelete($event)"
  ></app-goal-task-table>
</div>
