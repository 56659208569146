<div class="container my-5">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
      <li class="breadcrumb-item"><a routerLink="/goals">Metas</a></li>
      <li class="breadcrumb-item active" aria-current="page">Adicionar Nova</li>
    </ol>
  </nav>

  <h1>Cadastro de Meta</h1>

  <div *ngIf="isSaving" class="backdrop">
    <div class="spinner-border text-light" role="status">
      <span class="visually-hidden">Salvando as Informações...</span>
    </div>
  </div>

  <div id="fullPageContent" class="container my-5">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <section class="mb-4">
        <div class="row">
          <div class="col-md-4 mb-3">
            <label for="title" class="form-label">Título</label>
            <input type="text" id="title" formControlName="title" class="form-control"
                   [ngClass]="{'is-invalid': formGroup.get('title')?.invalid && formGroup.get('title')?.touched,
                   'is-valid': formGroup.get('title')?.valid && formGroup.get('title')?.touched}" />
            <div *ngIf="formGroup.get('title')?.invalid && formGroup.get('title')?.touched" class="invalid-feedback">
              O título é obrigatório.
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <label for="startDate" class="form-label">Data de Início</label>
            <input type="date" id="startDate" formControlName="startDate" class="form-control"
                   [ngClass]="{'is-invalid': formGroup.get('startDate')?.invalid && formGroup.get('startDate')?.touched,
                   'is-valid': formGroup.get('startDate')?.valid && formGroup.get('startDate')?.touched}" />
            <div *ngIf="formGroup.get('startDate')?.invalid && formGroup.get('startDate')?.touched" class="invalid-feedback">
              A data de início é obrigatória.
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <label for="endDate" class="form-label">Data de Fim</label>
            <input type="date" id="endDate" formControlName="endDate" class="form-control"
                   [ngClass]="{'is-invalid': formGroup.get('endDate')?.invalid && formGroup.get('endDate')?.touched,
                   'is-valid': formGroup.get('endDate')?.valid && formGroup.get('endDate')?.touched}" />
            <div *ngIf="formGroup.get('endDate')?.invalid && formGroup.get('endDate')?.touched" class="invalid-feedback">
              A data de fim é obrigatória.
            </div>
          </div>
          <div class="col-md-12 mb-3">
            <label for="description" class="form-label">Descricão</label>
            <textarea class="form-control" id="description" rows="3" formControlName="description"
              placeholder="Digite sua descrição aqui"
              [ngClass]="{'is-invalid': formGroup.get('description')?.invalid && formGroup.get('description')?.touched,
                   'is-valid': formGroup.get('description')?.valid && formGroup.get('description')?.touched}"></textarea>
            <div *ngIf="formGroup.get('description')?.invalid && formGroup.get('description')?.touched" class="invalid-feedback">
              A descricão é obrigatória.
            </div>
          </div>
        </div>
      </section>

      <div class="d-grid gap-2" *ngIf="!isSaving">
        <button type="submit" class="btn btn-primary" [disabled]="formGroup.invalid">Salvar</button>
      </div>

      <div *ngIf="isSaving" class="spinner-container">
        <div class="spinner"></div>
      </div>
    </form>
  </div>

</div>
