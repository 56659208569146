<div id="demo">
  <table border="1">
    <tr>
      <td>Row 1, Column 1</td>
      <td>Row 1, Column 2</td>
    </tr>

    <tr>
      <td>Row 2, Column 1</td>
      <td>Row 2, Column 2</td>
    </tr>
  </table>
</div>
<br />
<button printSectionId="demo" ngxPrint>print</button>
