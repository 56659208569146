<div class="container my-3">
  <!-- Campo de busca e Filtro por Cargo -->
  <div class="row mb-3">
    <div class="col-md-6">
      <input
        type="text"
        class="form-control"
        placeholder="Buscar..."
        [(ngModel)]="searchQuery"
        (input)="search()"
      />
    </div>

    <div class="col-md-6">
      <select class="form-select" [(ngModel)]="selectedJobTitle" (change)="filterByJobTitle()">
        <option value="">Todos os Cargos</option>
        <option *ngFor="let job of getUniqueJobTitles()" [value]="job">{{ job }}</option>
      </select>
    </div>
  </div>

  <!-- Tabela -->
  <div class="table-responsive">
    <table class="table table-striped table-hover">
      <thead class="thead-light">
      <tr>
        <!-- Cabeçalhos da Tabela -->
        <th *ngFor="let header of headers">{{ header }}</th>
        <th>Ações</th> <!-- Coluna de ações -->
      </tr>
      </thead>
      <tbody>
      <!-- Dados da Tabela Paginados -->
      <tr *ngFor="let row of paginatedData">
        <td *ngFor="let col of row">{{ col }}</td>
        <td>
          <!-- Botões de Ação -->
          <button class="btn btn-primary btn-sm" (click)="onEdit(row)">
            Editar
          </button>
          <button class="btn btn-danger btn-sm mx-1" (click)="onDelete(row)">
            Deletar
          </button>
          <button class="btn btn-info btn-sm" (click)="onView(row)">
            Visualizar
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <!-- Controles de Paginação -->
  <nav aria-label="Table pagination">
    <ul class="pagination justify-content-center">
      <li class="page-item" [class.disabled]="currentPage === 1">
        <button class="page-link" (click)="changePage(currentPage - 1)" [disabled]="currentPage === 1">
          Anterior
        </button>
      </li>

      <li class="page-item" *ngFor="let page of [].constructor(totalPages); let i = index" [class.active]="i + 1 === currentPage">
        <button class="page-link" (click)="changePage(i + 1)">
          {{ i + 1 }}
        </button>
      </li>

      <li class="page-item" [class.disabled]="currentPage === totalPages">
        <button class="page-link" (click)="changePage(currentPage + 1)" [disabled]="currentPage === totalPages">
          Próximo
        </button>
      </li>
    </ul>
  </nav>
</div>
